<template>
  <div class="elv-valuation-future-detail-content">
    <p class="elv-valuation-future-detail-content-nav">
      {{ t('valuation.future.unrealizedAndAdjustment') }}
    </p>
    <div class="elv-valuation-future-detail-content-body">
      <el-tabs
        v-model="activeTab"
        class="elv-valuation-future-detail-content-body-source-tabs"
        @tab-change="onTabsChange"
      >
        <el-tab-pane name="1">
          <template #label>
            <span class="custom-tabs-label"> {{ t('valuation.future.calculation') }} </span>
          </template>
          <CalculationTable
            :tableType="valuationFilterSaveType.FUTURE"
            :headerList="calculationTableHeader"
            :tableData="calculationTableData"
            :pageParams="calculationPageParams"
            :tableLoading="calculationTableLoading"
            @onChangePage="onChangePage"
            @onChangePageSize="onChangePageSize"
            @onChangeSort="onChangeSort"
            @onOpenRawDataEndingDialog="onOpenRawDataEndingDialog"
            @onOpenRawDataRealizedDialog="onOpenRawDataRealizedDialog"
            @onOpenFutureFilterOverlay="onOpenFutureFilterOverlay"
          />
        </el-tab-pane>
        <el-tab-pane name="2">
          <template #label>
            <span class="custom-tabs-label"> {{ t('valuation.future.rawData') }} </span>
          </template>
          <RawDataTable
            :tableType="valuationFilterSaveType.FUTURE"
            :headerList="rawDataTableHeader"
            :tableData="rawDataTableData"
            :pageParams="rawDataPageParams"
            :tableLoading="rawDataTableLoading"
            @onChangePage="onChangeRawDataPage"
            @onChangePageSize="onChangeRawDataPageSize"
            @onChangeSort="onChangeRawDataSort"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <FilterCalculationOverlay
    ref="calculationFilterRef"
    :filterType="valuationFilterSaveType.FUTURE"
    @onFilterData="onFilterData"
  />
  <FilterRawDataDialog ref="rawDataFilterRef" :filterType="valuationFilterSaveType.FUTURE" />
</template>

<script setup lang="ts">
import { $t } from '@/i18n/index'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import { useValuationStore } from '@/stores/modules/valuation'
import RawDataTable from '../components/FutureOptions/RawDataTable.vue'
import { rawDataFilterType, valuationFilterSaveType } from '@/config/valuation'
import CalculationTable from '../components/FutureOptions/CalculationTable.vue'
import FilterRawDataDialog from '../components/FutureOptions/FilterRawDataDialog.vue'
import FilterCalculationOverlay from '../components/FutureOptions/FilterCalculationOverlay.vue'

const { t } = useI18n()
const entityStore = useEntityStore()
const valuationStore = useValuationStore()

const activeTab = ref('1')
const rawDataTableLoading = ref(false)
const calculationTableLoading = ref(false)
const rawDataTableDataLoadFinish = ref(false)
const rawDataFilterRef = useTemplateRef('rawDataFilterRef')
const calculationFilterRef = useTemplateRef('calculationFilterRef')

const calculationPageParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})
const rawDataPageParams = ref({
  page: 1,
  limit: 20,
  sortField: 'datetime',
  sortDirection: 'DESC'
})

const calculationTableHeader = [
  {
    dataIndex: 'origin',
    title: $t('valuation.future.origin'),
    slotName: 'origin',
    width: 100
  },
  {
    dataIndex: 'adjusting',
    title: $t('valuation.crypto.adjustingId'),
    slotName: 'adjusting',
    min_width: 120
  },
  {
    dataIndex: 'period',
    title: $t('valuation.future.period'),
    slotName: 'period',
    width: 100
  },
  {
    dataIndex: 'account',
    title: $t('common.account'),
    slotName: 'account',
    width: 100
  },
  {
    dataIndex: 'symbol',
    title: $t('valuation.future.symbol'),
    slotName: 'symbol',
    width: 180
  },
  {
    dataIndex: 'positionSide',
    title: $t('report.positionAndSide'),
    slotName: 'positionSide',
    width: 100
  },
  {
    dataIndex: 'marginAsset',
    title: $t('valuation.assets.marginAsset'),
    slotName: 'marginAsset',
    width: 100
  },
  {
    dataIndex: 'unrealizedAndChange',
    title: $t('valuation.future.unrealizedAndChange'),
    slotName: 'unrealizedAndChange',
    width: 200,
    headerAlign: 'right'
  },
  {
    dataIndex: 'equality',
    title: '=',
    slotName: 'equality',
    width: 30
  },
  {
    dataIndex: 'ending',
    title: $t('valuation.future.ending'),
    slotName: 'ending',
    width: 100,
    headerAlign: 'right'
  },
  {
    dataIndex: 'deleteSymbol',
    title: '-',
    slotName: 'deleteSymbol',
    width: 30
  },
  {
    dataIndex: 'beginning',
    title: $t('valuation.future.beginning'),
    slotName: 'beginning',
    width: 120,
    headerAlign: 'right',
    isCustomHeader: true,
    headerSlotName: 'beginningHeader'
  },
  {
    dataIndex: 'addSymbol',
    title: '+',
    slotName: 'addSymbol',
    width: 30
  },
  {
    dataIndex: 'realizedPAndL',
    title: $t('valuation.future.realizedPAndL'),
    slotName: 'realizedPAndL',
    width: 100,
    headerAlign: 'right'
  },
  {
    dataIndex: 'journalNo',
    title: $t('valuation.future.journalNo'),
    slotName: 'journalNo'
  }
]
const rawDataTableHeader = [
  {
    dataIndex: 'origin',
    title: $t('valuation.future.origin'),
    slotName: 'origin',
    width: 100
  },
  {
    dataIndex: 'account',
    title: $t('common.account'),
    slotName: 'account',
    width: 200
  },
  {
    dataIndex: 'dateTime',
    title: $t('common.dateTime'),
    slotName: 'dateTime',
    width: 150
  },
  {
    dataIndex: 'referenceNo',
    title: $t('report.referenceNo'),
    slotName: 'referenceNo',
    width: 150
  },
  {
    dataIndex: 'type',
    title: $t('common.type'),
    slotName: 'type',
    width: 150
  },
  {
    dataIndex: 'symbol',
    title: $t('valuation.future.symbol'),
    slotName: 'symbol'
  },
  {
    dataIndex: 'positionSide',
    title: $t('report.positionAndSide'),
    slotName: 'positionSide',
    width: 100
  },
  {
    dataIndex: 'marginAsset',
    title: $t('valuation.assets.marginAsset'),
    slotName: 'marginAsset',
    width: 100
  },
  {
    dataIndex: 'pAndL',
    title: $t('valuation.future.PAndL'),
    slotName: 'pAndL',
    headerAlign: 'right',
    align: 'right',
    width: 100
  }
]

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const calculationTableData = computed(() => {
  return valuationStore.futureAdjustmentList
})

const rawDataTableData = computed(() => {
  return valuationStore.futureRawDataList
})

/**
 * 获取Future RawData数据
 */
const getRawDataList = async () => {
  rawDataTableLoading.value = true
  try {
    await valuationStore.fetchFutureRawDataList(entityId.value, {
      page: rawDataPageParams.value.page,
      limit: rawDataPageParams.value.limit,
      sortField: rawDataPageParams.value.sortField,
      sortDirection: rawDataPageParams.value.sortDirection
    })
    rawDataTableDataLoadFinish.value = true
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    rawDataTableLoading.value = false
  }
}

/**
 * 获取Future Calculation数据
 */
const getCalculationList = async () => {
  calculationTableLoading.value = true

  let params: Record<string, unknown> = {
    page: calculationPageParams.value.page,
    limit: calculationPageParams.value.limit,
    sortField: calculationPageParams.value.sortField,
    sortDirection: calculationPageParams.value.sortDirection
  }

  const currentFilterData = valuationStore.valuationFilterList.find((item: any) => {
    return item.type === valuationFilterSaveType.FUTURE
  })
  const currentSaveList = currentFilterData?.list || []
  const entityFilter = currentSaveList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityFilter)) {
    params = {
      ...params,
      ...entityFilter.data
    }
    if (params?.checkedAssetInfoList) {
      delete params?.checkedAssetInfoList
    }
  }
  try {
    await valuationStore.fetchFutureAdjustmentList(entityId.value, params)
  } catch (error: any) {
    calculationTableLoading.value = false
    ElMessage.error(error.message)
  } finally {
    calculationTableLoading.value = false
  }
}

/**
 * 切换tab页 Calculation/rawData
 */
const onTabsChange = () => {
  // if (rawDataTableDataLoadFinish.value) return
  if (activeTab.value === '1') {
    calculationPageParams.value.page = 1
    getCalculationList()
  } else {
    rawDataPageParams.value.page = 1
    getRawDataList()
  }
}

/**
 * Calculation分页切换页码
 * @param page 页码
 */
const onChangePage = (page: number) => {
  calculationPageParams.value.page = page
  getCalculationList()
}

/**
 * Calculation分页切换每页显示数量
 * @param pageSize 每页显示数量
 */
const onChangePageSize = (pageSize: number) => {
  calculationPageParams.value.limit = pageSize
  getCalculationList()
}

/**
 * RawData分页切换页码
 * @param page 页码
 */
const onChangeRawDataPage = (page: number) => {
  rawDataPageParams.value.page = page
  getRawDataList()
}

/**
 * RawData分页切换每页显示数量
 * @param pageSize 每页显示数量
 */
const onChangeRawDataPageSize = (pageSize: number) => {
  rawDataPageParams.value.limit = pageSize
  getRawDataList()
}

/**
 * Calculation排序
 * @param sortType 排序类型 DESC/ASC
 */
const onChangeSort = (sortType: string) => {
  calculationPageParams.value.sortDirection = sortType
  calculationPageParams.value.page = 1
  getCalculationList()
}

/**
 * RawData排序
 * @param sortType 排序类型 DESC/ASC
 */
const onChangeRawDataSort = (sortType: string) => {
  rawDataPageParams.value.sortDirection = sortType
  rawDataPageParams.value.page = 1
  getRawDataList()
}

/**
 * 打开未实现和调整弹窗
 * @param params 单条Calculation数据
 */
const onOpenRawDataEndingDialog = (params: any) => {
  const { data } = params
  rawDataFilterRef.value?.changeDialogStatus(rawDataFilterType.ENDING, data)
}

/**
 * 打开已实现弹窗
 * @param params 单条Calculation数据
 */
const onOpenRawDataRealizedDialog = (params: any) => {
  const { data } = params
  rawDataFilterRef.value?.changeDialogStatus(rawDataFilterType.REALIZED, data)
}

/**
 * 打开Future calculation筛选界面
 */
const onOpenFutureFilterOverlay = () => {
  calculationFilterRef.value?.onCheckDrawerStatus()
}

/**
 * 筛选Calculation重新获取数据
 */
const onFilterData = () => {
  calculationPageParams.value.page = 1
  getCalculationList()
}

/**
 * 初始化页面数据
 */
const initData = () => {
  activeTab.value = '1'
  rawDataTableDataLoadFinish.value = false
  getCalculationList()
}
onMounted(initData)
onBeforeUnmount(() => {
  valuationStore.clearFutureAdjustmentList()
  valuationStore.clearFutureRawDataList()
})
</script>

<style lang="scss">
.elv-valuation-future-detail-content {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-valuation-future-detail-content-nav {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-weight: 700;
    margin-top: 24px;
    padding: 0px 20px;
  }

  .elv-valuation-future-detail-content-body {
    width: 100%;
    height: calc(100% - 54px);
    position: relative;

    .elv-valuation-future-detail-content-body-source-tabs {
      height: 100%;
      position: relative;

      .el-tabs__header {
        .el-tabs__active-bar {
          height: 1px;
        }

        .custom-tabs-label {
          font-weight: 600;
        }
      }

      .el-tabs__content {
        width: 100%;
        height: calc(100% - 47px);
        position: relative;

        .el-tab-pane {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
    }
  }
}
</style>
